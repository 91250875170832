@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font family */
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Scroll bar window right */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #50a794;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00521f;
}

/* Protect image to downloaded */
img {
  pointer-events: none;
}
